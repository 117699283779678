<template>
  <v-autocomplete
    v-model="selEmpleado"
    :items="empleados"
    :item-text="getEmpleadoText"
    item-value="id"
    label="Empleado"
    cache-items
    :loading="loading"
    :rules="empleadoRules"
    :search-input.sync="searchEmpleado"
    no-data-text="No se encontraron resultados"
    @change="onChange"
    ref="autocomplete"
    :disabled="disabled"
  >
  </v-autocomplete>
</template>

<script>
import EmpleadosService from "@/services/EmpleadosService";
import PresentismoService from "@/services/PresentismoService";

export default {
  name: "EmpleadoAutocomplete",
  components: {},

  data() {
    return {
      formValidity: false,
      selEmpleado: null,
      searchEmpleado: null,
      empleados: [],
      loading: false,
      empleadoRules: [(v) => !!v || "Debe seleccionar un empleado."],
      disabled: false,
    };
  },
  props: {
    empleadoGuardado: {
      type: Object,
      required: false,
    },
    conContratacion: {
      type: Boolean,
      required: false,
    },
  },
  created() {
    if (this.empleadoGuardado) {
      this.selEmpleado = this.empleadoGuardado.id;
      this.empleados.push(this.empleadoGuardado);
    }
    // console.log("EG: " + this.empleadoGuardado + " SE: " + this.selEmpleado);
  },
  methods: {
    onChange() {
      this.$emit(
        "empleado-changed",
        this.selEmpleado,
        this.getEmpleadoText(
          this.selEmpleado
            ? this.empleados.find((obj) => obj.id === this.selEmpleado)
            : null
        )
      );
    },

    getEmpleadoText(item) {
      if (!item) return;
      return `${item.apellidos}, ${item.nombres} (${item.documento_nro})`;
    },
    querySelections(v) {
      if (v.length < 3) return;
      this.loading = true;

      if (this.conContratacion) {
        PresentismoService.getEmpleados(v)
          .then((response) => {
            this.empleados = response.data.results;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      } else {
        EmpleadosService.getEmpleados(v)
          .then((response) => {
            this.empleados = response.data.results;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      }
    },
  },
  watch: {
    searchEmpleado(val) {
      // console.log("watch! val:" + val + " selEmpleado:" + this.selEmpleado);
      val && val !== this.selEmpleado && this.querySelections(val);
    },
  },
};
</script>
